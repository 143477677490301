import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// project imports

import { useUserContext } from 'contexts/UserContext';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const navigate = useNavigate();
    const { isAuthenticated } = useUserContext();
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('login', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
