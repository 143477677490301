// eslint-disable-next-line import/prefer-default-export
export function setUserTheme(theme) {
    window.localStorage.setItem('theme', theme);
}

export function getUserTheme() {
    return window.localStorage.getItem('theme');
}

export function setMode(mode) {
    window.localStorage.setItem('mode', mode);
}

export function getMode() {
    return window.localStorage.getItem('mode');
}
