// material-ui
import bcplogo from 'assets/images/landing/PartyStarterlogo.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => <img width="120" height="90" src={bcplogo} alt="Logo" />;

export default Logo;
