// New File Added
import axios from 'axios';
import messages from 'utils/messages';
// eslint-disable-next-line import/no-cycle
import { getToken, removeToken } from 'utils/token';

const invalidTokenMessages = [messages.sessionExpiry, messages.invalidToken, messages.malformedToken];

const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

export const setAuthHeader = (token) => {
    client.defaults.headers.common.Authorization = `Bearer ${token}`;
};

setAuthHeader(getToken());

const onSuccess = function (response) {
    console.debug('Request Successful!', response);
    return response.data;
};

const onError = function (error) {
    console.error('Request Failed:', error.config);
    if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
        if (error.response.data.status === 401 && invalidTokenMessages.includes(error.response.data.message)) {
            removeToken();
            window.location.reload();
        }
    } else {
        // Something else happened while setting up the request
        // triggered the error
        console.error('Error Message:', error.message);
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
        errMsg: !error?.response
            ? 'Network Issue!'
            : error?.response?.data?.message || `${error?.response?.data?.errors[0].param} ${error?.response?.data?.errors[0].msg}`,
        status: error?.response?.status || 'not status'
    });
};

client.interceptors.response.use(onSuccess, onError);
export default client;
