import client from 'utils/axiosConfig';

export function signup({ body }) {
    return client.post(`/api/v1/admins/auth/signUp`, body);
}

export function login(body) {
    return client.post(`/api/v1/admins/auth/login`, body);
}

export function getUser() {
    return client.get(`/api/v1/user`);
}
