import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MaintenanceError from 'views/pages/maintenance/Error';
// import Profile from 'views/pages/vendor/VendorDetailPage/comp/Profile';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// pages
const VendorDetailPage = Loadable(lazy(() => import('views/pages/vendor/VendorDetailPage')));
const Profile = Loadable(lazy(() => import('views/pages/vendor/VendorDetailPage/comp/Profile')));
const Gallery = Loadable(lazy(() => import('views/pages/vendor/VendorDetailPage/comp/Gallery')));
const VendorMeetings = Loadable(lazy(() => import('views/pages/vendor/VendorDetailPage/comp/Meetings')));
const VendorEventList = Loadable(lazy(() => import('views/pages/vendor/VendorDetailPage/comp/VendorEventList')));
const VendorBookings = Loadable(lazy(() => import('views/pages/vendor/VendorDetailPage/comp/Bookings')));
const CancelationPolicy = Loadable(lazy(() => import('views/pages/vendor/VendorDetailPage/comp/CancelationPolicy')));

const VendorListPage = Loadable(lazy(() => import('views/pages/vendor/VendorListPage')));

const CustomerListPage = Loadable(lazy(() => import('views/pages/customer/CustomerListPage')));
const CustomerDetailPage = Loadable(lazy(() => import('views/pages/customer/CustomerDetailPage')));
const CustomerProfile = Loadable(lazy(() => import('views/pages/customer/CustomerDetailPage/comp/Profile')));
const CustomerEventList = Loadable(lazy(() => import('views/pages/customer/CustomerDetailPage/comp/CustomerEventList')));
const CustomerBookings = Loadable(lazy(() => import('views/pages/customer/CustomerDetailPage/comp/Bookings')));
const CustomerMeetings = Loadable(lazy(() => import('views/pages/customer/CustomerDetailPage/comp/Meetings')));

const EventListPage = Loadable(lazy(() => import('views/pages/event/EventListPage')));
const EventDetailPage = Loadable(lazy(() => import('views/pages/event/EventDetailPage')));
const EventQuotes = Loadable(lazy(() => import('views/pages/event/EventDetailPage/comp/Quotes')));
const EventBookings = Loadable(lazy(() => import('views/pages/event/EventDetailPage/comp/Bookings')));
const EventMeetings = Loadable(lazy(() => import('views/pages/event/EventDetailPage/comp/Meetings')));

const EventDetails = Loadable(lazy(() => import('views/pages/event/EventDetailPage/comp/Detail')));

const MeetingListPage = Loadable(lazy(() => import('views/pages/meeting/MeetingListPage')));
const MeetingDetailPage = Loadable(lazy(() => import('views/pages/meeting/MeetingDetailPage')));

const BookingListPage = Loadable(lazy(() => import('views/pages/booking/BookingListPage')));
const BookingDetailPage = Loadable(lazy(() => import('views/pages/booking/BookingDetailPage')));

const ServicesListPage = Loadable(lazy(() => import('views/pages/services/ServicesListPage')));
const ServicesEditPage = Loadable(lazy(() => import('views/pages/services/ServicesEditPage')));
const PlatformFeePage = Loadable(lazy(() => import('views/pages/settings/PlatformFeePage')));

// ==============================|| MAIN ROUTING ||============================== //

const PrimaryRoutes = {
    path: '/',
    element: (
        <>
            <AuthGuard>
                <MainLayout />
            </AuthGuard>
        </>
    ),
    children: [
        {
            path: '/',
            element: (
                <>
                    <DashboardDefault />
                </>
            )
        },
        {
            path: '/customers',
            element: <CustomerListPage />
        },
        {
            path: '/customers/:id',
            element: <CustomerDetailPage />,
            children: [
                {
                    path: 'profile',
                    element: <CustomerProfile />
                },
                {
                    path: 'events',
                    element: <CustomerEventList />
                },
                {
                    path: 'bookings',
                    element: <CustomerBookings />
                },
                {
                    path: 'meetings',
                    element: <CustomerMeetings />
                }
            ]
        },
        {
            path: '/vendors',
            element: <VendorListPage />
        },
        {
            path: '/vendors/:id/',
            element: <VendorDetailPage />,
            children: [
                {
                    path: 'profile',
                    element: <Profile />
                },
                {
                    path: 'gallery',
                    element: <Gallery />
                },
                {
                    path: 'events',
                    element: <VendorEventList />
                },
                {
                    path: 'meetings',
                    element: <VendorMeetings />
                },
                {
                    path: 'bookings',
                    element: <VendorBookings />
                },
                {
                    path: 'policy',
                    element: <CancelationPolicy />
                }
            ]
        },
        {
            path: '/events',
            element: <EventListPage />
        },
        {
            path: '/events/:id',
            element: <EventDetailPage />,
            children: [
                {
                    path: 'details',
                    element: <EventDetails />
                },
                {
                    path: 'quotes',
                    element: <EventQuotes />
                },
                // {
                //     path: 'meetings',
                //     element: <EventMeetings />
                // },
                {
                    path: 'bookings',
                    element: <EventBookings />
                }
            ]
        },
        {
            path: '/meetings',
            element: <MeetingListPage />
        },
        {
            path: '/meetings/:id',
            element: <MeetingDetailPage />
        },
        {
            path: '/bookings',
            element: <BookingListPage />
        },
        {
            path: '/bookings/:id',
            element: <BookingDetailPage />
        },
        {
            path: '/services',
            element: <ServicesListPage />
        },
        {
            path: '/services/:id',
            element: <ServicesEditPage />
        },
        {
            path: '/settings',
            element: <PlatformFeePage />
        },
        {
            path: '*',
            element: <MaintenanceError />
        }
    ]
};

export default PrimaryRoutes;
