// third-party
import { FormattedMessage } from 'react-intl';

// assets
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ChatIcon from '@mui/icons-material/Chat';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
            type: 'item',
            url: '/',
            icon: DashboardOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'customers',
            title: <FormattedMessage id="Customers" defaultMessage="Customers" />,
            type: 'item',
            url: '/customers',
            icon: ArticleOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'vendors',
            title: <FormattedMessage id="Vendors" defaultMessage="Vendors" />,
            type: 'item',
            url: '/vendors',
            icon: ArticleOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'events',
            title: <FormattedMessage id="Events" defaultMessage="Events" />,
            type: 'item',
            url: '/events',
            icon: ArticleOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'meetings',
            title: <FormattedMessage id="Meetings" defaultMessage="Meetings" />,
            type: 'item',
            url: '/meetings',
            icon: ArticleOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'bookings',
            title: <FormattedMessage id="Bookings" defaultMessage="Bookings" />,
            type: 'item',
            url: '/bookings',
            icon: ArticleOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'services',
            title: <FormattedMessage id="Services" defaultMessage="Services" />,
            type: 'item',
            url: '/services',
            icon: ArticleOutlinedIcon,
            breadcrumbs: false
        }
        // {
        //     id: 'transactions',
        //     title: <FormattedMessage id="Transactions" />,
        //     type: 'item',
        //     url: '/transactions',
        //     icon: PaidOutlinedIcon,
        //     breadcrumbs: false
        // }
    ]
};

export default dashboard;
