const messages = {
    passwordLength: 'Password must be at least 8 characters long',
    invalidPassword: 'Password must include at least one upper case letter, one lower case letter, and one numeric digit',
    invalidEmail: 'Must be a valid email address',
    usernameNotAvailable: 'Username not available',
    signedUp: 'Successfully signed up!',
    loggedIn: 'Successfully logged in!',
    acceptAllInformationIsTrue: 'You must accept that the given information is true',
    invalidDate: 'Invalid date',
    requiredField: 'This field is required',
    invalidValue: 'Invalid value',
    sessionExpiry: 'Session has been expired!',
    invalidToken: 'Invalid token',
    malformedToken: 'invalid signature',
    created: (name) => `${name} has been created successfully!`
};

export default messages;
