// New file added
import { getUser } from 'api/v1/auth';
import jwtDecode from 'jwt-decode';
import * as React from 'react';
import { getToken, removeToken, setToken as setTokenInLocalStorage } from 'utils/token';

const UserContext = React.createContext();

export function UserProvider({ children }) {
    const [token, setToken] = React.useState(getToken());
    let defaultUser = null;
    if (token) {
        defaultUser = jwtDecode(token);
    }

    const [user, setUser] = React.useState(defaultUser);

    async function fetchData() {
        const res = await getUser();
        setUser((prev) => ({ ...prev, ...res.data }));
    }

    React.useEffect(() => {
        if (token) {
            try {
                const decoded = jwtDecode(token);
                if (Date.now() >= decoded.exp * 1000) {
                    throw Error('token expired');
                }
                // To get token data
                setTokenInLocalStorage(token);
                fetchData();
            } catch (error) {
                removeToken();
                window.location.reload();
            }
        }
    }, [token]);

    return (
        <UserContext.Provider
            value={{
                isAuthenticated: Boolean(Object.values(user ?? {}).length),
                user,
                setToken
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export function useUserContext() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserContext can only be used inside UserProvider');
    }
    return context;
}
