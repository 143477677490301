// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const DATE_FORMAT = 'dd-MM-yyyy';
export const DATE_FORMAT_TWO = 'hh aaa, do MMM yyyy';
export const successStatus = ['completed', 'paid', 'approved', 'completedByVendor', 'completedByCustomer'];
export const errorStatus = ['cancelled', 'disputed', 'rejected'];
export const warningStatus = ['pending', 'suspended'];

export const O_A_LEVEL_SUBJECTS = [
    {
        id: 1,
        name: 'Accounting',
        selected: true,
        teachers: [
            { id: 2, name: 'Prof. Shoaib Khan', selected: true },
            { id: 3, name: 'Prof. Samyan Wahla', selected: false },
            { id: 4, name: 'Prof. Bilal Ahmad', selected: false }
        ]
    },
    {
        id: 5,
        name: 'Computer Science',
        teachers: [
            { id: 6, name: 'Prof. Shams', selected: false },
            { id: 7, name: 'Prof. Khalil Ahmad', selected: false }
        ]
    },
    {
        id: 8,
        name: 'Chemistry',
        selected: true,
        teachers: [
            { id: 9, name: 'Prof. Faisal Quereshi', selected: true },
            { id: 10, name: 'Prof. Shan Rasheed', selected: false }
        ]
    },
    {
        id: 11,
        name: 'Literature',
        selected: false,
        teachers: [
            { id: 12, name: 'Prof. Atif Sikandar', selected: false },
            { id: 13, name: 'Prof. Nasir Amir', selected: false }
        ]
    },
    {
        id: 14,
        name: 'Mathematics',
        selected: true,
        teachers: [
            { id: 15, name: 'Prof. Sahar Waqar', selected: true },
            { id: 16, name: 'Prof. Muzammil Tariq', selected: false }
        ]
    },
    {
        id: 17,
        name: 'Information and communication technology',
        selected: false,
        teachers: [
            { id: 18, name: 'Prof. Abdul Rehman', selected: false },
            { id: 19, name: 'Prof. Waqas Rana', selected: false }
        ]
    },
    {
        id: 20,
        name: 'Economics',
        selected: true,
        teachers: [
            { id: 21, name: 'Prof. Ameer Abbas', selected: false },
            { id: 22, name: 'Prof. Zohaib Munir', selected: true }
        ]
    },
    {
        id: 23,
        name: 'Agriculture',
        selected: true,
        teachers: [
            { id: 24, name: 'Prof. Sanan Babar', selected: true },
            { id: 25, name: 'Prof. Sohain Ahmad', selected: false }
        ]
    }
];

// Question data
export const basicData = [
    {
        id: 'basic1',
        title: 'How can I update my profile?',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum ex ex, eu luctus ligula mattis in. In cursus dui et lectus tempus, quis ultricies turpis placerat. Duis egestas ultricies enim, at euismod arcu mattis sed. Phasellus risus diam, commodo in arcu ut, vehicula mattis leo. Curabitur rhoncus ut nisi ac bibendum.'
    },
    {
        id: 'basic2',
        title: 'How can I make request of data correction?',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    },
    {
        id: 'basic3',
        title: 'How can I see my academic Report?',
        content:
            'Morbi tincidunt odio est, vel aliquam lectus porta eget. Sed ac dui nec neque tempus dapibus ac non nibh. Nunc vel tincidunt nulla. Aenean hendrerit iaculis commodo. Proin mollis urna id velit ullamcorper, in ultricies magna tempus. Suspendisse dignissim enim ipsum, sit amet venenatis arcu consequat ac. Vestibulum sit amet massa gravida, tristique nisl non, laoreet arcu. Fusce vel nulla scelerisque, laoreet augue ut, varius arcu. Proin mollis elit in sodales porta.'
    }
];

// Student: Academic Year detail mark sheet
export const ACADEMIC_YEARS = {
    2019: [
        {
            title: 'Term 1',
            record: [
                {
                    subject: 'Business 9609',
                    teacher: 'Shaiba Rizwan',
                    cgpa: 2.8,
                    midTermAssessment: { percentage: 74, grade: 'A', gpa: 4.5 },
                    midYearExams: { percentage: 42, grade: 'E', gpa: 1 }
                },
                {
                    subject: 'Information Technology (9626)',
                    teacher: 'Syeda Zoya Ali',
                    cgpa: 1,
                    midTermAssessment: { percentage: 58, grade: 'C', gpa: 3 },
                    midYearExams: { percentage: 24, grade: 'U', gpa: -1 }
                }
            ]
        },
        {
            title: 'Term 2',
            record: [
                {
                    subject: 'Information Technology (9626)',
                    teacher: 'Syeda Zoya Ali',
                    cgpa: 1,
                    midTermAssessment: {},
                    midYearExams: { percentage: 41, grade: 'E', gpa: 1 }
                },
                {
                    subject: 'Business 9609',
                    teacher: 'Shaiba Rizwan',
                    cgpa: -1,
                    midTermAssessment: {},
                    midYearExams: { percentage: 20, grade: 'U', gpa: -1 }
                }
            ]
        }
    ],
    2020: [
        {
            title: 'Term 3',
            record: [
                {
                    subject: 'Probability 9609',
                    teacher: 'Shoaib Khan',
                    cgpa: 2.8,
                    midTermAssessment: { percentage: 74, grade: 'A', gpa: 4.5 },
                    midYearExams: { percentage: 42, grade: 'E', gpa: 1 }
                },
                {
                    subject: 'Differential Equation (9626)',
                    teacher: 'Arslan Bukhari',
                    cgpa: 1,
                    midTermAssessment: { percentage: 58, grade: 'C', gpa: 3 },
                    midYearExams: { percentage: 24, grade: 'U', gpa: -1 }
                }
            ]
        },
        {
            title: 'Term 4',
            record: [
                {
                    subject: 'Machine Learning (9626)',
                    teacher: 'Syeda Zoya Ali',
                    cgpa: 1,
                    midTermAssessment: {},
                    midYearExams: { percentage: 41, grade: 'E', gpa: 1 }
                },
                {
                    subject: 'Applied Physics 9609',
                    teacher: 'Shaiba Rizwan',
                    cgpa: -1,
                    midTermAssessment: {},
                    midYearExams: { percentage: 20, grade: 'U', gpa: -1 }
                }
            ]
        }
    ],
    2021: [
        {
            title: 'Term 5',
            record: [
                {
                    subject: 'Business 9609',
                    teacher: 'Shaiba Rizwan',
                    cgpa: 2.8,
                    midTermAssessment: { percentage: 74, grade: 'A', gpa: 4.5 },
                    midYearExams: { percentage: 42, grade: 'E', gpa: 1 }
                },
                {
                    subject: 'Information Technology (9626)',
                    teacher: 'Syeda Zoya Ali',
                    cgpa: 1,
                    midTermAssessment: { percentage: 58, grade: 'C', gpa: 3 },
                    midYearExams: { percentage: 24, grade: 'U', gpa: -1 }
                }
            ]
        },
        {
            title: 'Term 6',
            record: [
                {
                    subject: 'Information Technology (9626)',
                    teacher: 'Syeda Zoya Ali',
                    cgpa: 1,
                    midTermAssessment: {},
                    midYearExams: { percentage: 41, grade: 'E', gpa: 1 }
                },
                {
                    subject: 'Business 9609',
                    teacher: 'Shaiba Rizwan',
                    cgpa: -1,
                    midTermAssessment: {},
                    midYearExams: { percentage: 20, grade: 'U', gpa: -1 }
                }
            ]
        }
    ]
};
